import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Logo from "./../../images/icon-blue.svg";

type LogoLinkProps = {
  large?: boolean;
};

const LogoLink = (props: LogoLinkProps) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteName
          }
        }
      }
    `
  );

  return (
    <Link to="/" className="flex flex-row items-center sm:mr-20">
      <img src={Logo} className={"h-5 " + (props.large ? "sm:h-10" : "sm:h-7")} />
      <div
        className={
          "tracking-tighter pl-2 text-xl " + (props.large ? "sm:pl-4 font-black sm:text-4xl" : "sm:pl-3 font-bold sm:text-2xl")
        }
      >
        {site.siteMetadata.siteName}
      </div>
    </Link>
  );
};

export default LogoLink;
