export const navLinks = [
  {
    href: "/markets/BTC_USDT",
    title: "Exchange",
  },
  {
    href: "/markets",
    title: "Market",
  },
  {
    href: "/news",
    title: "News",
  },
  {
    href: "/support",
    title: "Support",
  },
  {
    href: "/buy-crypto",
    title: "Buy crypto",
  },
  {
    href: "/rewards",
    title: "Rewards",
  },
];
