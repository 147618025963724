import { Avatar } from "evergreen-ui";
import { Link, navigate } from "gatsby";
import React, { useState } from "react";
import LogoLink from "./logo-link";
import CloseIcon from "./../../images/close.svg";
import MenuIcon from "./../../images/menu.svg";
import { navLinks } from "../../data/links";
import { useLocalStorage, useReadLocalStorage } from "usehooks-ts";
import { AppUser } from "../../model/types";
import { getAvatarString } from "../../utils/tools";

const MobileHeader = () => {
  const [loggedIn, setLoggedIn] = useLocalStorage<boolean>("logged_in", false);
  const user: AppUser | null = useReadLocalStorage<AppUser>("user");

  const [collapsed, setCollapsed] = useState<boolean>(true);

  function onMenuClick() {
    setCollapsed(!collapsed);
  }

  return (
    <div className="block lg:hidden">
      <div className="grid grid-cols-2 h-12">
        <LogoLink />
        <div className="flex justify-end items-center">
          {loggedIn && (
            <Link to="/dashboard" className="flex mr-4">
              <Avatar name={getAvatarString(user)} size={32} />
            </Link>
          )}
          <button onClick={onMenuClick} className="block w-8 h-8 mr-1">
            {collapsed ? (
              <img className="w-full h-full" src={MenuIcon}></img>
            ) : (
              <img className="w-full h-full" src={CloseIcon}></img>
            )}
          </button>
        </div>
      </div>
      <nav className={(collapsed ? "hidden" : "block") + " absolute top-12 left-0 w-full z-50"}>
        <div className="bg-white flex flex-col items-center gap-y-1 pt-4 pb-14 border-b">
          {!loggedIn && (
            <Link to="/signin" className="font-medium text-xs p-3">
              Login
            </Link>
          )}
          {!loggedIn && (
            <Link
              to="/signup"
              className="font-semibold text-xs px-6 py-4 bg-blue-700 text-white rounded my-3"
            >
              Get started
            </Link>
          )}
          <ul className="flex flex-col items-center gap-y-1">
            {navLinks.map((item) => (
              <li key={item.href} className="p-3">
                <Link to={item.href} className="font-medium text-xs">
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
          {loggedIn && (
            <button
              className="font-medium text-xs p-3"
              onClick={() => {
                setLoggedIn(false);
                navigate("/signin");
              }}
            >
              Log Out
            </button>
          )}
        </div>
        <div
          style={{ height: "9999px" }}
          className="w-full bg-black opacity-10"
          onClick={onMenuClick}
        ></div>
      </nav>
    </div>
  );
};

export default MobileHeader;
